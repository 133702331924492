import React, { Component } from 'react';
import { Box, Button, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@material-ui/core';
import './Home.scss';
import ParticlesWrapper from '../../components/ParticlesWrapper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { RoomRoute, OnlyDesktopRoute } from '../../Routing';
import { RedirectIfMobile } from '../../utils/DetectMobile';
import { makeid } from '../../utils/General';
import { generateHouseInput, createHouse } from '../../utils/RoomApi';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});

class Home extends Component {
    state = {
        roomToJoin: "",
        roomToCreate: makeid(14),
        roomPassword: "",
        maxCapacity: 30,
        isPrivate: false,
        isNameCustom: true,
        currentPrice: 0,
        error: "",
        loading: false,
    }

    componentDidMount() {
        RedirectIfMobile(OnlyDesktopRoute)
    }

    updatePrice() {
        const price = this.getCurrentPrice();
        this.setState({
            currentPrice: price,
        })
    }

    getCurrentPrice() {
        let price = 0;
        if (this.state.isPrivate) price += 1;
        if (this.state.isNameCustom) price += 1;
        if (this.state.maxCapacity === 50) price += 1;
        if (this.state.maxCapacity === 100) price += 2;
        console.log(price)
        return price
    }

    async createHouse() {
        this.setState({ loading: true });
        const input = generateHouseInput(
            this.state.roomToCreate,
            this.state.maxCapacity,
            this.state.roomPassword
        )
        const houseData = await createHouse(input)
        console.log(houseData)
        if (houseData && houseData.id) {
            window.location.href = "." + RoomRoute + "/" + houseData.id;
        } else {
            this.setState({
                error: "Fail to create the room, the name may be already used"
            })
        }
        this.setState({ loading: false });
    }

    joinRoom() {
        if (!this.state.roomToJoin) return
        if (this.state.roomToJoin.startsWith("https://")) {
            window.location.href = this.state.roomToJoin;
        } else {
            window.location.href = "." + RoomRoute + "/" + this.state.roomToJoin;
        }
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleChangeCheckbox = stateName => event => {
        let roomToCreate = this.state.roomToCreate;
        if (stateName === 'isNameCustom' && !event.target.checked) {
            roomToCreate = makeid(14)
        }
        this.setState({
            [stateName]: event.target.checked,
            roomToCreate: roomToCreate,
        });
        this.updatePrice();
    };

    render() {
        //const price = this.getCurrentPrice();
        const { loading } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Box id='Home'>
                    <Box id="particles-js"><ParticlesWrapper /></Box>
                    <Box id="content">
                        <Box className="container" display="flex" flexDirection="column">
                            <Box flexGrow={1} />
                            <Box display="flex" flexDirection="row">
                                <Box flexGrow={1} />
                                <Box className="create">
                                    <Typography variant="h5" style={{ textAlign: "center" }}>Create a room</Typography>
                                    <Typography style={{ textAlign: "center", marginBottom: 15 }}>It will be active for a month</Typography>
                                    <Box display="none">
                                        <FormControlLabel
                                            control={<Checkbox
                                                disabled={loading}
                                                checked={this.state.isNameCustom}
                                                onChange={this.handleChangeCheckbox("isNameCustom")}
                                                value="isNameCustom"
                                            />}
                                            label="Custom name"
                                        />
                                    </Box>
                                    <Box display={this.state.isNameCustom ? "block" : "none"}>
                                        <TextField
                                            autoFocus
                                            style={{ marginTop: 0, marginBottom: 15 }}
                                            disabled={!this.state.isNameCustom || loading}
                                            value={this.state.roomToCreate}
                                            onChange={this.handleChange("roomToCreate")}
                                            margin="dense"
                                            id="roomToCreate"
                                            label="Room name"
                                            fullWidth
                                            color="primary"
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </Box>
                                    <Box display='none'>
                                        <Box>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    disabled={loading}
                                                    checked={this.state.isPrivate}
                                                    onChange={this.handleChangeCheckbox("isPrivate")}
                                                    value="isPrivate"
                                                />}
                                                label="Private room"
                                            />
                                        </Box>
                                        <Box display={this.state.isPrivate ? "block" : "none"}>
                                            <TextField
                                                disabled={loading}
                                                style={{ marginTop: 0, marginBottom: 15 }}
                                                autoFocus
                                                value={this.state.roomPassword}
                                                onChange={this.handleChange("roomPassword")}
                                                margin="dense"
                                                id="roomPassword"
                                                label="Password"
                                                fullWidth
                                                color="primary"
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display='none' style={{ marginTop: 15, marginBottom: 30 }}>
                                        <FormControl>
                                            <InputLabel id="select-capacity">Max room capacity</InputLabel>
                                            <Select
                                                disabled={loading}
                                                labelId="select-capacity-label"
                                                id="select-capacity"
                                                value={this.state.maxCapacity}
                                                onChange={this.handleChange("maxCapacity")}
                                            >
                                                <MenuItem value={30} default><strong>30</strong> participants</MenuItem>
                                                <MenuItem value={50}><strong>50 </strong> participants</MenuItem>
                                                <MenuItem value={100}><strong>100</strong> participants</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box display={this.state.error ? "block" : "none"} style={{ marginBottom: 5 }}>
                                        <Typography style={{ backgroundColor: "white", padding: 10, color: "red" }}>{this.state.error}</Typography>
                                    </Box>
                                    <Box className="textCenter">
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            color="secondary"
                                            size='large'
                                            onClick={() => this.createHouse()}
                                        >
                                            Create
                                        </Button>
                                    </Box>
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <Box flexGrow={1} />
                                    <Box className="join" >
                                        <Typography variant="h5">Join a room</Typography>
                                        <Box className="textInput">
                                            <TextField
                                                disabled={loading}
                                                autoFocus
                                                value={this.state.roomToJoin}
                                                onChange={this.handleChange("roomToJoin")}
                                                margin="dense"
                                                id="roomToJoin"
                                                label="Room name or url"
                                                fullWidth
                                                color="primary"
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Box>
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            color="secondary"
                                            size='large'
                                            onClick={() => this.joinRoom()}
                                        >
                                            Join
                                        </Button>
                                    </Box>
                                    <Box flexGrow={1} />
                                </Box>
                                <Box flexGrow={1} />
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
export default Home