// Return an object clustering participant by chat and a list of solo user
export function generateChatsClusters(participants) {
    let chats = [];
    let soloUsers = [];
    for (let i = 0; i < participants.length; i++) {
        let userChat = null;
        if (participants[i].chat) {
            userChat = participants[i].chat;
        }
        if (userChat) {
            let found = false;
            for (let j = 0; j < chats.length; j++) {
                if (chats[j].chat === userChat) {
                    found = true;
                    chats[j].users.push(participants[i])
                    break
                }
            }
            if (!found) {
                chats.push({ chat: userChat, positionTop: 0, positionLeft: 0, users: [participants[i]], shouldConnect: false })
            }
        } else {
            soloUsers.push(participants[i]);
        }
    }
    return { chats: chats, soloUsers: soloUsers }
}

// Calculate barycenter of between particiant position of the same chat
// Return an updated chat object with position
export function calculateChatsPosition(chatClusters) {
    for (let i = 0; i < chatClusters.length; i++) {
        // Do not draw chat for lonly user
        if (chatClusters[i].users.length <= 1) continue
        let chatTop = 0;
        let chatLeft = 0;
        for (let j = 0; j < chatClusters[i].users.length; j++) {
            chatTop += chatClusters[i].users[j].positionTop;
            chatLeft += chatClusters[i].users[j].positionLeft;
        }

        chatTop = chatTop / chatClusters[i].users.length;
        chatLeft = chatLeft / chatClusters[i].users.length;

        chatClusters[i].positionTop = chatTop;
        chatClusters[i].positionLeft = chatLeft;
    }
    return chatClusters
}

export function screenToWord(screenPosition, wordPosition) {
    return {top: screenPosition.top - wordPosition.top, left: screenPosition.left - wordPosition.left}
}

export function wordToScreen(relativePosition, wordPosition) {
    return {top: relativePosition.top + wordPosition.top, left: relativePosition.left + wordPosition.left}
}
