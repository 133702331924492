import React, { Component } from 'react';
import './GetDrink.scss';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import LaunchAPayment from '../../components/LaunchAPayment/LaunchAPayment';
import { STRIPE_PUBLIC_KEY } from './../../utils/Parameters';
import { ConfirmDrinkRoute, CancelDrinkRoute } from '../../Routing';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});


class GetDrink extends Component {
    render() {
        const base_url = window.location.origin;

        const stripePublicKey = STRIPE_PUBLIC_KEY;

        const apiName = "stripeapi";
        const apiEndpoint = "/checkoutdrink";
        const name = "Get a drink on aperoom.io";
        const description = "Thanks for your support!";
        const images = ['https://i.pinimg.com/originals/cf/e5/d5/cfe5d555a451cab7a905301545d190b5.png'];
        const amount = this.props.match.params.price * 100;
        const currency = "eur";
        const quantity = 1;
        const success_url = base_url + ConfirmDrinkRoute;
        const cancel_url = base_url + CancelDrinkRoute;
        const client_reference_id = this.props.match.params.id;
        const metadata = { drink: this.props.match.params.accessory }

        return (
            <ThemeProvider theme={theme}>
                <Box display="flex" flexDirection="column" alignItems="center" style={{ height: '100vh' }}>
                    <Box flexGrow={1} />
                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                        <Box flexGrow={1} />
                        <StripeProvider apiKey={stripePublicKey}>
                            <Elements>
                                <LaunchAPayment
                                    apiName={apiName}
                                    apiEndpoint={apiEndpoint}
                                    name={name}
                                    description={description}
                                    images={images}
                                    amount={amount}
                                    currency={currency}
                                    quantity={quantity}
                                    success_url={success_url}
                                    cancel_url={cancel_url}
                                    client_reference_id={client_reference_id}
                                    metadata={metadata}
                                />
                            </Elements>
                        </StripeProvider>
                        <Box flexGrow={1} />
                    </Box>
                    <Box flexGrow={1} />
                </Box>

            </ThemeProvider>
        );
    }
}
export default withRouter(GetDrink)