export const noConnectionZoneSize = 250;
export const noSeeZoneSize = 150;

export function getAvatar(style, pseudo) {
    if (!style || !pseudo) return ''
    if (style === 'male') {
        return 'https://avatars.dicebear.com/v2/male/' + pseudo.replace(/[^a-zA-Z]/g, "") + '.svg'
    } else if (style === 'female') {
        return 'https://avatars.dicebear.com/v2/female/' + pseudo.replace(/[^a-zA-Z]/g, "") + '.svg'
    } else if (style === 'robot') {
        return 'https://avatars.dicebear.com/v2/bottts/' + pseudo.replace(/[^a-zA-Z]/g, "") + '.svg'
    } else {
        return 'https://api.adorable.io/avatars/100/' + pseudo.replace(/[^a-zA-Z]/g, "") + '.png'
    }
}

export function canBeSeen(top, left) {
    if (top < noSeeZoneSize && left < noSeeZoneSize) return false
    return true
}

export function canBeConnected(top, left) {
    if (top < noConnectionZoneSize && left < noConnectionZoneSize) return false
    return true
}