import React, { Component } from 'react';
import { Typography, Dialog, DialogTitle, TextField, DialogActions, DialogContent, Button, ThemeProvider, createMuiTheme, FormControlLabel, Radio, Box } from '@material-ui/core';
import { v4 as uuid } from 'uuid'
import { withRouter } from 'react-router-dom';
import { createParticipant, getParticipantsByRoom } from '../../../utils/participantsApi';
import { getHouseInfo, roomInfo } from '../../../utils/RoomApi';
import { RedirectIfHouseNotFound } from '../../../utils/General';
import { getAvatar } from '../../../utils/AvatarApi';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});

class EnterRoomDialog extends Component {
    state = {
        userPseudo: "",
        dialogOpen: true,
        error: null,
        selectedAvatarStyle: 'robot',
        avatarUrl: '',
        houseInfo: null,
    }

    constructor() {
        super();
        this.handleDialogValidation = this.handleDialogValidation.bind(this)
        this.handleEnterDialog = this.handleEnterDialog.bind(this)
    }

    async handleEnterDialog() {
        const houseInfo = await getHouseInfo(this.props.match.params.id)
        RedirectIfHouseNotFound(houseInfo)
        this.setState({ houseInfo: houseInfo })
    }

    async handleDialogValidation(event) {
        event.preventDefault()
        if (!this.state.userPseudo) return
        console.log(this.state.userPseudo)
        this.setState({ loading: true, error: null });

        const houseInfo = this.state.houseInfo;
        RedirectIfHouseNotFound(houseInfo)

        if (!houseInfo.id) {
            const error = "Fail to get room info"
            this.setState({ loading: false, error: error });
            return
        }

        const room = roomInfo(houseInfo, 0)
        console.log(room)
        const roomId = room.roomId;

        const participants = await getParticipantsByRoom(roomId)
        const maxCapacity = room.maxCapacity;
        console.log(participants)
        if (participants.length > maxCapacity) {
            const error = "Maximum room capacity reached: " + maxCapacity;
            this.setState({ loading: false, error: error });
            return
        }

        for (let i = 0; i < participants.length; i++) {
            if (participants[i].pseudo === this.state.userPseudo) {
                const error = "Error: Someone else in the room already have this pseudo.... Please try again.";
                this.setState({ loading: false, error: error });
                return
            }
        }

        const myId = await this.createMyself(this.state.userPseudo, roomId)
        if (!houseInfo.id || !myId) {
            const error = "Fail to create your user"
            this.setState({ loading: false, error: error });
            return
        }
        console.log(houseInfo)

        this.onEnter(myId, houseInfo)
        this.setState({ loading: false, dialogOpen: false, myId: myId })
    };

    onEnter(myId, houseInfo) {
        this.props.onEnter(myId, houseInfo)
    }

    async createMyself(pseudo, roomId) {
        console.log(roomId)
        const myId = uuid();
        const avatarUrl = this.state.avatarUrl;
        const res = await createParticipant(myId, roomId, pseudo, avatarUrl, 'rgba(150, 50, 0, 1)', 100, 100)
        console.log(res)
        if (res) {
            return res
        } else {
            return false
        }
    }

    handleChangePseudo = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
        this.updateAvatar(null, event.target.value)
    };

    handleChangeAvatar = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
        this.updateAvatar(event.target.value, null)
    };

    updateAvatar(styleArg = null, pseudoArg = null) {
        let style = styleArg;
        if (!style) style = this.state.selectedAvatarStyle
        let pseudo = pseudoArg;
        if (!pseudo) pseudo = this.state.userPseudo
        const avatarUrl = getAvatar(style, pseudo);
        this.setState({ avatarUrl: avatarUrl })
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.state.dialogOpen}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                    onEnter={this.handleEnterDialog}
                >
                    <DialogTitle id="form-dialog-title">Who are you?</DialogTitle>
                    <form onSubmit={this.handleDialogValidation}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                value={this.state.userPseudo}
                                onChange={this.handleChangePseudo("userPseudo")}
                                margin="dense"
                                id="Pseudo"
                                label="Pseudo"
                                fullWidth
                                color="primary"
                            />
                            {
                                this.state.error ?
                                    <Typography style={{ color: "red" }}>{this.state.error}</Typography>
                                    :
                                    null
                            }
                            <Box>
                                <FormControlLabel value="end" control={
                                    <Radio
                                        checked={this.state.selectedAvatarStyle === 'robot'}
                                        onChange={this.handleChangeAvatar("selectedAvatarStyle")}
                                        value="robot"
                                        name="radio-button-avatar"
                                    />}
                                    label="Robot"
                                />
                                <FormControlLabel value="end" control={
                                    <Radio
                                        checked={this.state.selectedAvatarStyle === 'male'}
                                        onChange={this.handleChangeAvatar("selectedAvatarStyle")}
                                        value="male"
                                        name="radio-button-avatar"
                                    />}
                                    label="Male"
                                />
                                <FormControlLabel value="end" control={
                                    <Radio
                                        checked={this.state.selectedAvatarStyle === 'female'}
                                        onChange={this.handleChangeAvatar("selectedAvatarStyle")}
                                        value="female"
                                        name="radio-button-avatar"
                                    />}
                                    label="Female"
                                />
                                <FormControlLabel value="end" control={
                                    <Radio
                                        checked={this.state.selectedAvatarStyle === 'monster'}
                                        onChange={this.handleChangeAvatar("selectedAvatarStyle")}
                                        value="monster"
                                        name="radio-button-avatar"
                                    />}
                                    label="Monster"
                                />
                            </Box>
                            <Box display={this.state.avatarUrl ? "block" : "none"}
                                style={{
                                    height: 100,
                                    width: 100,
                                    backgroundImage: 'url(' + this.state.avatarUrl + ')',
                                    borderRadius: '50%'
                                }} />
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" onClick={this.handleDialogValidation} color="secondary" variant="contained">
                                Go!
                        </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </ThemeProvider>
        );
    }
}
export default withRouter(EnterRoomDialog)