import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';

export async function getParticipantsByRoom(roomId) {
    try {
        const rawParticipantByRoom = await API.graphql({
            query: queries.participantByRoom,
            variables: {
                room: roomId,
                limit: 2000
            },
            authMode: 'API_KEY'
        });
        let participantByRoom = rawParticipantByRoom.data.participantByRoom.items;
        return participantByRoom
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function getParticipant(id) {
    try {
        const rawParticipant = await API.graphql({
            query: queries.getParticipant,
            variables: { id: id },
            authMode: 'API_KEY'
        });
        const participant = rawParticipant.data.getParticipant;
        return participant
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function createParticipant(id, room, pseudo, avatar, color, top, left, accessory = null) {
    let input = {
        id: id,
        room: room,
        pseudo: pseudo,
        avatar: avatar,
        color: color,
        positionTop: top,
        positionLeft: left,
        lastActivity: new Date().toISOString(),
        lastPing: new Date().toISOString(),
    }
    if (accessory) {
        input.accessory = accessory;
    }
    try {
        await API.graphql({
            query: mutations.createParticipant,
            variables: { input: input },
            authMode: 'API_KEY'
        });
        return id
    } catch (e) {
        console.log("Fail to create participant.");
        console.log(e)
        return false
    }
}

export function shouldUpdateMyPing(participant) {
    if (!participant) return
    const timeDiffFromPing = Math.abs(new Date(participant.lastPing) - new Date())
    console.log('Not updated for ' + timeDiffFromPing, 'master')
    // My ping is more than 5min
    if (timeDiffFromPing > 5 * 60000) {
        console.log("Update my ping")
        updateMyPing(participant.id)
    }
}

export async function updateMyPing(id) {
    if (!id) return
    console.log('Updaing my ping', 'master')
    const date = new Date().toISOString();
    try {
        await API.graphql({
            query: mutations.updateParticipant,
            variables: {
                input: {
                    id: id,
                    lastActivity: date,
                    lastPing: date
                }
            },
            authMode: 'API_KEY'
        });
    } catch (e) {
        console.log(e)
    }
}

export async function pingParticipant(id) {
    if (!id) return
    const date = new Date().toISOString();
    try {
        await API.graphql({
            query: mutations.updateParticipant,
            variables: {
                input: {
                    id: id,
                    lastPing: date
                }
            },
            authMode: 'API_KEY'
        });
    } catch (e) {
        console.log(e)
    }
}

export async function updateParticipantChat(id, newChat) {
    if (!id) return
    try {
        await API.graphql({
            query: mutations.updateParticipant,
            variables: {
                input: {
                    id: id,
                    chat: newChat,
                    lastActivity: new Date().toISOString(),
                }
            },
            authMode: 'API_KEY'
        });
    } catch (e) {
        console.log(e)
    }
}

export async function updateParticipantPosition(id, top, left) {
    console.log('update position')
    if (!id) return
    const date = new Date().toISOString();
    try {
        await API.graphql({
            query: mutations.updateParticipant,
            variables: {
                input:
                {
                    id: id,
                    positionTop: top,
                    positionLeft: left,
                    lastActivity: date,
                    lastPing: date
                }
            },
            authMode: 'API_KEY'
        });
    } catch (e) {
        console.log(e)
    }
}

export async function updateParticipantPositionChat(id, top, left, newChat) {
    if (!id) return
    const date = new Date().toISOString();
    try {
        await API.graphql({
            query: mutations.updateParticipant,
            variables: {
                input:
                {
                    id: id,
                    chat: newChat,
                    positionTop: top,
                    positionLeft: left,
                    lastActivity: date,
                    lastPing: date
                }
            },
            authMode: 'API_KEY'
        });
    } catch (e) {
        console.log(e)
    }
}

export async function deleteParticipant(id) {
    if (!id) return
    try {
        await API.graphql({
            query: mutations.deleteParticipant,
            variables: { input: { id: id } },
            authMode: 'API_KEY'
        });
    } catch (e) {
        console.log(e)
    }
}