import { deleteParticipant, getParticipant } from "./participantsApi";

export const timeToConsiderInactive = 6.5 * 60000;

export function whosMaster(participants) {
    if (!participants) return null
    participants.sort((a, b) => a.id >= b.id ? -1 : 1)
    console.log(participants)
    let master = participants[0];
    for (let i = 0; i < participants.length; i++) {
        const timeDiffFromPing = Math.abs(new Date(participants[i].lastPing) - new Date())
        console.log(timeDiffFromPing)
        console.log(timeDiffFromPing < timeToConsiderInactive)
        // If the last ping was less than 6.5min ago, then the first found is the master
        if (timeDiffFromPing < timeToConsiderInactive) {
            master = participants[i];
            break
        }
    }
    return { master: master, participants: participants }
}

export function amIMaster(master, myId) {
    if (!master) return false
    return master.id === myId ? true : false
}

export function getLastPing(participants) {
    if (!participants) return null
    let lastPing = participants[0].lastPing;
    for (let i = 0; i < participants.length; i++) {
        if (lastPing < participants[i].lastPing) lastPing = participants[i].lastPing;
    }
    return lastPing
}

export function getOldestNotPingParticipant(participants, myId) {
    if (!participants) return null
    if (participants.length <= 1) return null
    let oldest = participants[0];
    for (let i = 0; i < participants.length; i++) {
        if (oldest.lastActivity > participants[i].lastActivity &&
            participants[i].lastActivity === participants[i].lastPing &&
            participants[i].id !== myId) oldest = participants[i];
    }
    return oldest
}

export function getMyIndex(participants, myId) {
    if (!myId) return null
    for (let i = 0; i < participants.length; i++) {
        if (participants[i].id === myId) return i;
    }
    return participants.length
}

export function revolution(participants, myId) {
    if (!myId) return null
    for (let i = 0; i < participants.length; i++) {
        if (participants[i].id === myId) break;
        deleteParticipant(participants[i].id)
    }
}

export function removeInactive(participants) {
    if (!participants) return null

    for (let i = 0; i < participants.length; i++) {
        // Check that this diff is more than 30s and delete
        const timeDiffFromPing = Math.abs(new Date(participants[i].lastPing) - new Date())
        console.log('time inactive for ' + participants[i].pseudo, 'master')
        if (timeDiffFromPing > timeToConsiderInactive) {
            // Before delete get it again to be sure
            console.log(participants[i].pseudo + ' is inactive to long', 'master')
            deleteParticipantAfterGet(participants[i].id)
        }

        // TODO
        // Inactivity can be detected if the user is alone in a chat (if active the user should remove it by himself)
    }
}

export async function deleteParticipantAfterGet(id) {
    const participant = await getParticipant(id);
    console.log(participant)
    if (!participant) return

    const timeDiffFromPing = Math.abs(new Date(participant.lastPing) - new Date())
    if (timeDiffFromPing > timeToConsiderInactive) {
        console.log('-----------------------Inactive------------------------------')
        console.log(participant)
        console.log(timeDiffFromPing)
        // Before delete get it again to be sure
        deleteParticipant(participant.id)
    }
}