import React, { Component } from 'react';
import { Box, ThemeProvider, createMuiTheme, Menu, MenuItem, Button } from '@material-ui/core';
import './Room.scss';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Draggable from 'react-draggable';
import Help from '../../components/Help/Help';

import { RedirectIfMobile } from '../../utils/DetectMobile';
import { OnlyDesktopRoute } from '../../Routing';
import RoomStage from '../../components/RoomStage/RoomStage';
import VideoConf from '../../components/VideoConf/VideoConf';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});

class Room extends Component {
    state = {
        allowFeeds: true,
        currentCursor: document.documentElement.clientWidth / 2,
        clientWidth: document.documentElement.clientWidth,
    }

    constructor() {
        super();
        this.handleDragCursor = this.handleDragCursor.bind(this)
        this.getWindowSize = this.getWindowSize.bind(this)

    }

    componentDidMount() {
        RedirectIfMobile(OnlyDesktopRoute);
        window.addEventListener("resize", this.getWindowSize);
    }

    getWindowSize() {
        // Get width and height of the window excluding scrollbars
        var w = document.documentElement.clientWidth;
        this.setState({
            currentCursor: w / 2,
            clientWidth: w,
        })
    }

    async handleDragCursor(event) {
        const newCursorX = event.pageX
        if (newCursorX > this.state.clientWidth || newCursorX < 0) {
            console.log("Off limit")
            return
        }
        //console.log(event.pageX)
        this.setState({ currentCursor: newCursorX })
    }


    onUpdateChat(newChat) {
        this.setState({ currentChat: newChat })
    }

    toogleMenu = event => {
        if (this.state.anchorEl) {
            this.setState({
                anchorEl: null,
            });
        } else {
            this.setState({
                anchorEl: event.target,
            });
        }
    }

    openOption = option => event => {
        this.toogleMenu(event)
        if (option === 'skribbl' && this.state.currentChat) {
            let chatNumber = this.state.currentChat.substr(this.state.currentChat.length - 14);
            const url = 'https://skribbl.io/?' + chatNumber;
            window.open(url, '_blank')
        }
    }

    render() {
        const { allowFeeds, currentChat, currentCursor, anchorEl } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Box id='RoomContainer' display='flex'>
                    <Box className='room' style={{ width: currentCursor, overflow: "hidden" }}>
                        <RoomStage onUpdateChat={(newChat) => this.onUpdateChat(newChat)} />
                    </Box>

                    <Box className='video-group' style={{ width: this.state.clientWidth - currentCursor }}>
                        {
                            allowFeeds && currentChat ?
                                //<Box>{currentChat}</ Box>
                                <VideoConf chat={currentChat} />
                                :
                                <Help />
                        }
                    </Box>

                    <Box className='cursor' style={{ position: "absolute", top: "50%" }}>
                        <Draggable
                            axis="x"
                            position={{ x: currentCursor, y: 0 }}
                            onDrag={this.handleDragCursor}
                        >
                            <Box className='avatar' style={{ position: "absolute", left: -40 }}>
                                <Fab variant="extended" color='secondary'>
                                    <KeyboardArrowLeftIcon />
                                    <KeyboardArrowRightIcon />
                                </Fab>
                            </Box>
                        </Draggable>
                    </Box>
                    <Box style={{ position: "absolute", bottom: 0, left: currentCursor - 120 }}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="secondary"
                            size='large'
                            onClick={this.toogleMenu}>
                            Games
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.toogleMenu}
                        >
                            <MenuItem disabled={!currentChat} onClick={this.openOption('skribbl')}>skribbl.io</MenuItem>
                        </Menu>
                    </Box>
                </Box >
            </ThemeProvider>
        );
    }
}
export default Room