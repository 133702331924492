function distanceSquare(object1, object2) {
    return Math.pow((object1.positionTop - object2.top), 2) + Math.pow((object1.positionLeft - object2.left), 2)
}

export function distanceSquareTopLeft(object1, object2) {
    return Math.pow((object1.top - object2.top), 2) + Math.pow((object1.left - object2.left), 2)
}
// Update chats object with "shouldConnect" if close enough to a chat
export function checkChatProximity(chats, avatarPosition) {
    for (let i = 0; i < chats.length; i++) {
        const chat = chats[i];
        const distance = distanceSquare(chat, avatarPosition);
        if (distance < 15000) {
            chats[i].shouldConnect = true;
        } else {
            chats[i].shouldConnect = false;
        }
    }
    return chats
}

// Update participant object with "wantConnection" if close enough to a solo participant
export function checkSoloProximity(participants, avatarPosition, myId) {
    for (let i = 0; i < participants.length; i++) {
        if (!participants[i].chat && participants[i].id !== myId) {
            const soloUser = participants[i];
            const distance = distanceSquare(soloUser, avatarPosition);
            if (distance < 30000) {
                participants[i].wantConnection = true;
            } else {
                participants[i].wantConnection = false;
            }
        } else {
            participants[i].wantConnection = false;
        }
    }
    return participants
}

// Reset participant object "wantConnection"
export function resetParticipantsWantConnection(participants) {
    for (let i = 0; i < participants.length; i++) {
        participants[i].wantConnection = false;
    }
    return participants
}

// Reset chat object "shouldConnect"
export function resetChatShouldConnect(chats) {
    for (let i = 0; i < chats.length; i++) {
        chats[i].shouldConnect = false;
    }
    return chats
}

