/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateParticipant = /* GraphQL */ `
  subscription OnCreateParticipant($room: String) {
    onCreateParticipant(room: $room) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
export const onUpdateParticipant = /* GraphQL */ `
  subscription OnUpdateParticipant($room: String) {
    onUpdateParticipant(room: $room) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
export const onDeleteParticipant = /* GraphQL */ `
  subscription OnDeleteParticipant($room: String) {
    onDeleteParticipant(room: $room) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
