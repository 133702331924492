import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import './Help.scss';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { getFact } from '../Facts/Facts';
import { withRouter } from 'react-router-dom';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});

class Help extends Component {
    state = {
        fact: getFact(),
    }

    render() {
        const { fact } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Box id='Help'>
                    <Box className="container" display="flex" flexDirection="column">
                        <Box flexGrow={1} />
                        <Box display="flex" flexDirection="row">
                            <Box flexGrow={1} />
                            <Box className="main-content">
                                <Typography variant="h5">To invite people</Typography>
                                <Typography>Share your current url or the room name: {this.props.match.params.id}</Typography>

                                <br /><br />
                                <Typography variant="h5">To start a new conversation</Typography>
                                <Typography>Drag and drop your avatar close to the person you want to talk to</Typography>

                                <br /><br />
                                <Typography variant="h5">To join a conversation</Typography>
                                <Typography>Drag and drop your avatar close to center of the conversation</Typography>

                                <br /><br />
                                <Typography variant="h5" >That's all, here is a useless fact to break the ice</Typography>
                                <Typography>{fact}</Typography>
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                        <Box flexGrow={1} />
                        <Box className="main-content" ><Typography>This tool manage video conferences based on <a rel="noopener noreferrer" href='https://github.com/jitsi/jitsi-meet' target="_blank">Jisti meet</a>
                        , but we are not (yet) hosting our own instance. Learn more about the instances you can be redirected to on <a rel="noopener noreferrer" href='https://framatalk.org/accueil/en/info' target="_blank">Framatalk</a></Typography></Box>
                        <Box style={{height: 50}} />
                        <Box className="version-content"><Typography>Version beta 0.2.6</Typography></Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
export default withRouter(Help)