import React, { Component } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import './VideoConf.scss';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});

class VideoConf extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box id='VideoConf'>
                    <Box style={{ position: "relative", height: '100%', width: '100%' }} display='flex' flexDirection='column'>
                        <Box flexGrow={1} />
                        <Box display='flex' flexDirection='raw'>
                            <Box flexGrow={1} />
                            <Box display='flex' alignItems='center'>
                                <CircularProgress style={{margin: 15}} /><Typography component='span'>Opening conversation, please wait...</Typography>
                            </Box>

                            <Box flexGrow={1} />
                        </Box>
                        <Box flexGrow={1} />
                    </Box>
                    <Box style={{ position: "relative", top: '-100%', height: '100%', width: '100%' }}>
                        < iframe title='video-conf'
                            src={this.props.chat}
                            allow="microphone; camera"
                            frameBorder="0"
                            style={{ overflow: 'hidden', height: '100%', width: '100%' }}>
                        </iframe>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
export default VideoConf