import React, { PureComponent } from 'react';
import { Typography, Dialog, DialogTitle, TextField, DialogActions, DialogContent, Button, ThemeProvider, createMuiTheme, FormControlLabel, Radio, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { GetDrinkRoute } from '../../../Routing';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});

class DonationDialog extends PureComponent {
    state = {
        selectedDrink: 'coffee',
        avatarUrl: "",
        price: 1,
        isPaymentLaunched: false,
    }

    constructor() {
        super();
        this.handleEnterDialog = this.handleEnterDialog.bind(this)
        this.openPayment = this.openPayment.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
    }

    handleEnterDialog() {
        // Update avatarUrl
        for (let i = 0; i < this.props.participants.length; i++) {
            if (this.props.participants[i].id === this.props.myId) {
                this.setState({ avatarUrl: this.props.participants[i].avatar })
            }
        }
    }

    handleChangeDrink = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleChangePrice = stateName => event => {
        const parsedInt = parseInt(event.target.value);
        console.log(parsedInt)
        if (parsedInt) {
            this.setState({
                [stateName]: parsedInt,
            });
        }

        return null
    };

    openPayment() {
        const url = GetDrinkRoute + '/' + this.props.myId + '/' + this.state.selectedDrink + '/' + this.state.price;
        window.open(url, '_blank')
        this.setState({ isPaymentLaunched: true })
    }

    closeDialog() {
        this.setState({
            selectedDrink: 'coffee',
            price: 1,
            isPaymentLaunched: false,
        })
        this.props.onClose()
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.props.open}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                    onEnter={this.handleEnterDialog}
                >
                    <DialogTitle id="form-dialog-title">Get a drink</DialogTitle>
                    <DialogContent>

                        <Typography>
                            Buy a virtual drink here to help aperoom.io to stay free for everyone.
                        </Typography>
                        <Typography component='span'>
                            <ul>
                                <li>
                                    Select what you want to get
                                </li>
                                <li>
                                    Fix how much you are willing to donate
                                </li>
                                <li>
                                    Your avatar will be upgrade after your payment
                                </li>
                            </ul>
                        </Typography>

                        <Box>
                            <FormControlLabel value="end" control={
                                <Radio
                                    checked={this.state.selectedDrink === 'coffee'}
                                    onChange={this.handleChangeDrink("selectedDrink")}
                                    value="coffee"
                                    name="radio-button-drink"
                                />}
                                label="Coffee"
                            />
                            <FormControlLabel value="end" control={
                                <Radio
                                    checked={this.state.selectedDrink === 'beer'}
                                    onChange={this.handleChangeDrink("selectedDrink")}
                                    value="beer"
                                    name="radio-button-drink"
                                />}
                                label="Beer"
                            />
                        </Box>
                        <Box display={this.state.avatarUrl ? "block" : "none"}
                            style={{
                                position: "relative",
                                marginBottom: 15,
                                marginTop: 10,
                                height: 100,
                                width: 100,
                                backgroundImage: 'url(' + this.state.avatarUrl + ')',
                                borderRadius: '50%'
                            }}>
                            {
                                this.state.selectedDrink === 'coffee' ?
                                    <Box style={{ fontSize: 40, position: "absolute", bottom: 0, right: 0 }}>
                                        <span role="img" aria-label="coffee">☕</span>
                                    </Box>
                                    :
                                    null
                            }
                            {
                                this.state.selectedDrink === 'beer' ?
                                    <Box style={{ fontSize: 40, position: "absolute", bottom: 0, right: 0 }}>
                                        <span role="img" aria-label="coffee">🍺</span>
                                    </Box>
                                    :
                                    null
                            }
                        </Box>
                        <Box style={{ marginBottom: 25 }}>
                            <TextField
                                id="outlined-value"
                                type="number"
                                label="Price"
                                value={this.state.price}
                                margin="normal"
                                onChange={this.handleChangePrice("price")}
                            />
                        </Box>
                        <Typography variant="caption">
                            <b>Warning</b>: The avatar customization will be visible only during this session as we no not store any information about you.
                            Leaving this website or closing your browser will end your session.
                        </Typography>
                        {
                            this.state.error ?
                                <Typography style={{ color: "red" }}>{this.state.error}</Typography>
                                :
                                null
                        }
                        {
                            this.state.isPaymentLaunched ?
                                <Box>
                                    <Typography>Thanks, the payment page have been opened in another tab.
                                        Use the button below to get back to the room.</Typography>

                                </Box>
                                :
                                null

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="secondary" variant="contained" onClick={() => this.closeDialog()}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={() => this.openPayment()}
                            disabled={this.state.isPaymentLaunched}
                        >
                            Get it! ({this.state.price} €)
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        );
    }
}
export default withRouter(DonationDialog)