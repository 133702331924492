import React, { PureComponent } from 'react';
import Particles from 'react-particles-js';

class ParticlesWrapper extends PureComponent {
    render() {
        /*
        const particlesParams = {
            "particles": {
                "number": { "value": window.innerWidth < 600 ? 180 : 200, "density": { "enable": true, "value_area": 800 } },
                "color": { "value": "#00426a" },
                "opacity": {
                    "value": 0.5,
                    "random": false,
                },
                "size": {
                    "value": 2,
                    "random": true,
                },
                "line_linked": {
                    "enable": true,
                    "distance": 150,
                    "color": "#006ba6",
                    "opacity": 0.4,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 2,
                    "direction": "none",
                    "random": false,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                }
            }
        };
        */
       /*
        const particlesParams = {
            "particles": {
                "number": { "value": 30, "density": { "enable": true, "value_area": 800 } },
                "color": { "value": "#00426a" },
                "opacity": {
                    "anim": {
                        "enable": false,
                    },
                    "value": 0.7,
                    "random": true,
                },
                "size": {
                    "value": 12,
                    "random": true,
                },
                "line_linked": {
                    "enable": true,
                    "distance": 250,
                    "color": "#006ba6",
                    "opacity": 0.4,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 1,
                    "direction": "none",
                    "random": false,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                }
            }
        };
*/
        const particlesParams = {
            "particles": {
              "number": {
                "value": 20,
                "density": {
                  "enable": true,
                  "value_area": 800
                }
              },
              "color": {
                "value": ["#BD10E0","#B8E986","#50E3C2","#FFD300","#E86363"]
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0,
                  "color": "#b6b2b2"
                }
              },
              "opacity": {
                "value": 0.8,
                "random": false,
                "anim": {
                  "enable": true,
                  "speed": 0.5,
                  "opacity_min": 0.5,
                  "sync": false
                }
              },
              "size": {
                "value": 30,
                "random": true,
                "anim": {
                  "enable": true,
                  "speed": 6,
                  "size_min": 0.7,
                  "sync": true
                }
              },
              "line_linked": {
                "enable": true,
                "distance": 300,
                "color": "#c8c8c8",
                "opacity": 0.8,
                "width": 1
              },
              "move": {
                "enable": true,
                "speed": 1.5,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "bounce",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": false,
                  "mode": "repulse"
                },
                "onclick": {
                  "enable": false,
                  "mode": "push"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 1
                  }
                },
                "bubble": {
                  "distance": 400,
                  "size": 40,
                  "duration": 2,
                  "opacity": 8,
                  "speed": 3
                },
                "repulse": {
                  "distance": 200,
                  "duration": 0.4
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true
          }
        return (
            <Particles
                className="particles"
                canvasClassName="particles-wrapper"
                params={particlesParams} />
        );
    }
}

export default ParticlesWrapper