import React from 'react';
import { Route, Switch } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import Home from './Pages/Home/Home'
import Room from './Pages/Room/Room'
import OnlyDesktop from './Pages/OnlyDesktop/OnlyDesktop'
import NoMatch from './Pages/NoMatch/NoMatch';
import GetDrink from './Pages/GetDrink/GetDrink';
import ConfirmDrink from './Pages/ConfirmDrink/ConfirmDrink';
import CancelDrink from './Pages/CancelDrink/CancelDrink';

export const HomeRoute = "/";
export const RoomRoute = "/r";
export const GetDrinkRoute = "/getdrink";
export const OnlyDesktopRoute = "/onlydesktop";
export const ConfirmDrinkRoute = "/confirmdrink";
export const CancelDrinkRoute = "/canceldrink";

class Routing extends React.Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path={HomeRoute} exact component={Home} />
                    <Route path={RoomRoute + '/:id'} component={Room} />
                    <Route path={GetDrinkRoute + '/:id/:accessory/:price'} component={GetDrink} />
                    <Route path={OnlyDesktopRoute} component={OnlyDesktop} />
                    <Route path={ConfirmDrinkRoute} component={ConfirmDrink} />
                    <Route path={CancelDrinkRoute} component={CancelDrink} />
                    <Route component={NoMatch} />
                </Switch>
            </div>
        );
    }
}
export default withRouter(Routing);