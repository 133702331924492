import { RoomRoute } from "../Routing";

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function checkIAmAlone(participants, currentChat, myId) {
    // Check if I am alone 
    for (let j = 0; j < participants.length; j++) {
        if (currentChat === participants[j].chat && participants[j].id !== myId) {
            return false;
        }
    }
    return true
}

export function RedirectIfHouseNotFound(houseInfo) {
    if (!houseInfo || !houseInfo.id) {
        window.location.href = RoomRoute;
    }
}