import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';
import { makeid } from './General';

export async function getHouseInfo(id) {
    try {
        const rawHouse = await API.graphql({
            query: queries.getHouse,
            variables: { id: id },
            authMode: 'API_KEY'
        });
        const house = rawHouse.data.getHouse;
        return house
    } catch (e) {
        console.log(e)
        return e
    }
}

export function roomInfo(house, roomIndex) {
    const rooms = JSON.parse(house.options).rooms;
    try {
        const roomInfo = rooms[roomIndex];
        return roomInfo
    } catch (e) {
        console.log(e)
        return e
    }
}

export async function createHouse(body) {
    try {
        const rawHouse = await API.post("aperoomApiRest", '/house', { body })
        const house = rawHouse.body.data.createHouse;
        return house
    } catch (e) {
        console.log(e)
        return null
    }
}

export function generateHouseInput(name, maxCapacity, password = null) {
    const options = generateHouseOptions(maxCapacity);

    const plan = 'free';
    const input = {
        id: name,
        options: options,
        isOwner: false,
        plan: plan,
        created: new Date().toISOString(),
    }
    // Add password
    if (password) {
        input.password = password;
        input.isPrivate = true;
    } else {
        input.isPrivate = false;
    }
    console.log(input)
    return input
}

export function generateHouseOptions(maxCapacity) {
    const roomId = makeid(20);
    let height = 1000;
    let width = 1000;
    if (maxCapacity === 50) {
        height = 2000;
        width = 2000;
    } else if (maxCapacity === 100) {
        height = 5000;
        width = 5000;
    }
    const options = {
        rooms: [
            {
                roomId: roomId,
                height: height,
                width: width,
                theme: 1,
                maxCapacity: maxCapacity
            }
        ]
    }
    return JSON.stringify(options)
}