/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getParticipant = /* GraphQL */ `
  query GetParticipant($id: ID!) {
    getParticipant(id: $id) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $id: ID
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        room
        chat
        pseudo
        avatar
        color
        positionTop
        positionLeft
        lastPing
        lastActivity
        accessory
      }
      nextToken
    }
  }
`;
export const participantByRoom = /* GraphQL */ `
  query ParticipantByRoom(
    $room: String
    $pseudo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantByRoom(
      room: $room
      pseudo: $pseudo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room
        chat
        pseudo
        avatar
        color
        positionTop
        positionLeft
        lastPing
        lastActivity
        accessory
      }
      nextToken
    }
  }
`;
export const participantByChat = /* GraphQL */ `
  query ParticipantByChat(
    $chat: String
    $pseudo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantByChat(
      chat: $chat
      pseudo: $pseudo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room
        chat
        pseudo
        avatar
        color
        positionTop
        positionLeft
        lastPing
        lastActivity
        accessory
      }
      nextToken
    }
  }
`;
export const getHouse = /* GraphQL */ `
  query GetHouse($id: String!) {
    getHouse(id: $id) {
      id
      options
      isPrivate
      password
      isOwner
      ownerPassword
      plan
      created
      creator
    }
  }
`;
export const listHouses = /* GraphQL */ `
  query ListHouses(
    $id: String
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHouses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        options
        isPrivate
        password
        isOwner
        ownerPassword
        plan
        created
        creator
      }
      nextToken
    }
  }
`;
export const houseByPlan = /* GraphQL */ `
  query HouseByPlan(
    $plan: Plan
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    houseByPlan(
      plan: $plan
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        options
        isPrivate
        password
        isOwner
        ownerPassword
        plan
        created
        creator
      }
      nextToken
    }
  }
`;
export const getServer = /* GraphQL */ `
  query GetServer($name: String!) {
    getServer(name: $name) {
      name
      status
      respTime
    }
  }
`;
export const listServers = /* GraphQL */ `
  query ListServers(
    $name: String
    $filter: ModelServerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServers(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        status
        respTime
      }
      nextToken
    }
  }
`;
export const serverByStatus = /* GraphQL */ `
  query ServerByStatus(
    $status: Status
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serverByStatus(
      status: $status
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        status
        respTime
      }
      nextToken
    }
  }
`;
