import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import './ConfirmDrink.scss';
import ParticlesWrapper from '../../components/ParticlesWrapper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});


class ConfirmDrink extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box id='ConfirmDrink'>
                    <Box id="particles-js"><ParticlesWrapper /></Box>
                    <Box id="content">
                        <Box className="container" display="flex" flexDirection="column">
                            <Box flexGrow={1} />
                            <Box display="flex" flexDirection="row">
                                <Box flexGrow={1} />
                                <Box className="form">
                                    <Typography variant="h6" style={{ marginBottom: 15 }}>
                                        Thanks for your support!
                                    </Typography>
                                    <Typography variant="h6" style={{ marginBottom: 15 }}>
                                        You can now close this tab and go back to the discussion room.
                                    </Typography>
                                </Box>
                                <Box flexGrow={1} />
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
export default ConfirmDrink