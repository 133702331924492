import React, { Component } from 'react';
import { Box, Button } from '@material-ui/core';
import './NoMatch.scss';
import ParticlesWrapper from '../../components/ParticlesWrapper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});


class NoMatch extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box id='NoMatch'>
                    <Box id="particles-js"><ParticlesWrapper /></Box>
                    <Box id="content">
                        <Box className="container" display="flex" flexDirection="column">
                            <Box flexGrow={1} />
                            <Box display="flex" flexDirection="row">
                                <Box flexGrow={1} />
                                <Box className="form">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size='large'
                                    >
                                        404 rooom not found
                                </Button>
                                </Box>
                                <Box flexGrow={1} />
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
export default NoMatch