import { makeid } from "./General";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";

let serversList = [];

export async function generateRandomChat() {
    const chatId = makeid(14)
    /*
    const roots = ["https://meet.jit.si/",
        "https://framatalk.org/",
        "https://framatalk.grifon.fr/",
        "https://meet.fab-l3.org/",
        "https://meet.roflcopter.fr/",
        "https://jitsi.riot.im/",
        "https://meeting.rezopole.net/",
        "https://meet.artifaille.fr/",
        "https://talk.fdn.fr/",
        "https://visio.opportunis.me/",
        "https://meet.nevers-libre.org/",
        "https://callme.tadaa.cloud/",
        "https://suricate.tv/",
        "https://jitsi.hivane.net/",
        "https://visio.paquerette.eu/",
        "https://conf.underworld.fr/",
        "https://visio.colibris-outilslibres.org/",
        "https://jitsi.hadoly.fr/",
        "https://allo.bim.land/",
        "https://meet.devloprog.org/",
        "https://jitsi.videodulib.re/",
        "https://meet.drycat.fr/",
        "https://meet.tedomum.net/"];
        */
    const servers = await getOnlineServers()
    const root = servers[Math.floor(Math.random() * servers.length)].name;
    const chatToConnect = root + chatId;
    return chatToConnect
}

export async function getOnlineServers(forceLoad = false) {
    if (serversList && serversList.length > 0 && !forceLoad) {
        console.log('servers from cache', "servers")
        return serversList
    }
    console.log("Get servers", "servers")
    try {
        const rawOnlineServers = await API.graphql({
            query: queries.serverByStatus,
            variables: {
                status: "online",
                limit: 2000
            },
            authMode: "API_KEY"
        });
        let serverByStatus = rawOnlineServers.data.serverByStatus.items;
        serversList = serverByStatus;
        return serverByStatus
    } catch (e) {
        console.log(e)
        return [{ name: "https://framatalk.org/", status: "online" }]
    }
}