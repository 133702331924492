import React, { Component } from 'react';
import { CircularProgress, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import { API } from 'aws-amplify';

class LaunchAPayment extends Component {
    async componentDidMount() {
        console.log("here")
        const body = {
            name: this.props.name,
            description: this.props.description,
            images: this.props.images,
            amount: this.props.amount,
            currency: this.props.currency,
            quantity: this.props.quantity,
            success_url: this.props.success_url,
            cancel_url: this.props.cancel_url,
            client_reference_id: this.props.client_reference_id,
            metadata: this.props.metadata
        }
        console.log(this.props.apiName)
        console.log(this.props.apiEndpoint)
        const response = await API.post(this.props.apiName, this.props.apiEndpoint, { body })
        console.log(response)
        this.props.stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: response.session.id
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
    }

    render() {
        return (
            <Box>
                <Typography variant='h5'>You will be redirected soon</Typography>
                <Box display='flex' margin={2}>
                    <Box flexGrow={1} />
                    <CircularProgress />
                    <Box flexGrow={1} />
                </Box>
            </Box>
        );
    }
}

LaunchAPayment.propTypes = {
    apiName: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,

    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    client_reference_id: PropTypes.string.isRequired,

    success_url: PropTypes.string.isRequired,
    cancel_url: PropTypes.string.isRequired,
};

export default injectStripe(LaunchAPayment);