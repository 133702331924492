import React from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routing from './Routing.js';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {
  return (
    <Router><Routing /></Router>
  );
}
export default App;