/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      id
      room
      chat
      pseudo
      avatar
      color
      positionTop
      positionLeft
      lastPing
      lastActivity
      accessory
    }
  }
`;
export const createHouse = /* GraphQL */ `
  mutation CreateHouse(
    $input: CreateHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    createHouse(input: $input, condition: $condition) {
      id
      options
      isPrivate
      password
      isOwner
      ownerPassword
      plan
      created
      creator
    }
  }
`;
export const updateHouse = /* GraphQL */ `
  mutation UpdateHouse(
    $input: UpdateHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    updateHouse(input: $input, condition: $condition) {
      id
      options
      isPrivate
      password
      isOwner
      ownerPassword
      plan
      created
      creator
    }
  }
`;
export const deleteHouse = /* GraphQL */ `
  mutation DeleteHouse(
    $input: DeleteHouseInput!
    $condition: ModelHouseConditionInput
  ) {
    deleteHouse(input: $input, condition: $condition) {
      id
      options
      isPrivate
      password
      isOwner
      ownerPassword
      plan
      created
      creator
    }
  }
`;
export const createServer = /* GraphQL */ `
  mutation CreateServer(
    $input: CreateServerInput!
    $condition: ModelServerConditionInput
  ) {
    createServer(input: $input, condition: $condition) {
      name
      status
      respTime
    }
  }
`;
export const updateServer = /* GraphQL */ `
  mutation UpdateServer(
    $input: UpdateServerInput!
    $condition: ModelServerConditionInput
  ) {
    updateServer(input: $input, condition: $condition) {
      name
      status
      respTime
    }
  }
`;
export const deleteServer = /* GraphQL */ `
  mutation DeleteServer(
    $input: DeleteServerInput!
    $condition: ModelServerConditionInput
  ) {
    deleteServer(input: $input, condition: $condition) {
      name
      status
      respTime
    }
  }
`;
