import React, { Component } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import './RoomStage.scss';
import * as subscriptions from '../../graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import Draggable from 'react-draggable';
import { withRouter } from 'react-router-dom';
import EnterRoomDialog from './EnterRoomDialog/EnterRoomDialog';
import { checkIAmAlone } from '../../utils/General';
import { getParticipantsByRoom, updateParticipantChat, deleteParticipant, updateParticipantPositionChat, updateParticipantPosition, shouldUpdateMyPing, createParticipant } from '../../utils/participantsApi';
import { generateChatsClusters, calculateChatsPosition, screenToWord } from '../../utils/DrawingHelpers';
import { checkChatProximity, checkSoloProximity, resetParticipantsWantConnection, resetChatShouldConnect, distanceSquareTopLeft } from '../../utils/MovementHelpers';
import { whosMaster, amIMaster, removeInactive } from '../../utils/RoomMasterHelpers';
import { generateRandomChat, getOnlineServers } from '../../utils/ChatHelpers';
import { roomInfo } from '../../utils/RoomApi';
import { canBeSeen, canBeConnected, noSeeZoneSize, noConnectionZoneSize } from '../../utils/AvatarApi';
import DonationDialog from './DonationDialog/DonationDialog';

const avatarSize = 100;
const avatarWantConnectionSize = 120;

class RoomStage extends Component {
    state = {
        houseInfo: {},
        rooomId: null,
        participants: [],
        avatarPosition: { top: 75, left: 75 },
        wordPosition: { top: 0, left: 0 },
        wordInfo: { width: 1000, height: 1000 },
        chats: [],
        soloUsers: [],
        currentChat: "",
        myId: null,
        master: null,
        currentMasterId: 0,

        donationDialogOpen: false,
        timersAtivated: false,

        leaving: false,
    }

    constructor() {
        super();
        this.handleDragAvatar = this.handleDragAvatar.bind(this)
        this.handleStopAvatar = this.handleStopAvatar.bind(this)
        this.roomMasterLoop = this.roomMasterLoop.bind(this)
        this.handleStopDragWord = this.handleStopDragWord.bind(this)
        this.checkForMyselfLoop = this.checkForMyselfLoop.bind(this)
        this.updateRoomMaster = this.updateRoomMaster.bind(this)
    }

    async componentDidMount() {
        // Update the server list
        getOnlineServers();
    }

    // Init when entering the room
    async entering(myId, houseInfo) {
        const room = roomInfo(houseInfo, 0)
        console.log(room)
        const roomId = room.roomId;
        const width = room.width;
        const height = room.height;
        this.init(roomId);
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            this.setState({ leaving: true })
            this.deleteMyself();
        });

        window.onbeforeunload = function () {
            return "Do you really want to leave our brilliant application?";
        };

        // Subscribe to new participant
        this.subscriptionNew = this.subscribeNewParticipant(roomId);
        // Subscribe to delete participant
        this.subscriptionDelete = this.subscribeDeleteParticipant(roomId);
        // Subscribe to update participant
        this.subscriptionUpdate = this.subscribeUpdateParticipant(roomId);

        // Room master
        // Start the rooom master loop
        this.setState({ myId: myId, houseInfo: houseInfo, roomId: roomId, wordInfo: { width: width, height: height } })
    }

    componentWillUnmount() {
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();

        // Remove the user from the api
        window.removeEventListener('onbeforeunload');
    }

    async init(roomId) {
        const participants = await getParticipantsByRoom(roomId)
        if (participants) {
            this.setState({ participants: participants });
            this.draw(participants)

            if (!this.state.timersAtivated) {
                console.log("activate timers", "master")
                setTimeout(this.updateRoomMaster(this.state.participants), 1000)
                setTimeout(this.checkForMyselfLoop, 1000)
                setTimeout(this.roomMasterLoop, 2000)
                this.setState({ timersAtivated: true })
            }
        }
    }



    /*
    -------------------------------------------------------------------------------------------------------
    Subscription Part
    -------------------------------------------------------------------------------------------------------
    */
    subscribeNewParticipant(roomId) {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateParticipant, { room: roomId })
        ).subscribe({
            next: (participantData) => {
                console.log('New participant', 'synchro')
                console.log(participantData, 'synchro')
                let newListParticipant = this.state.participants;
                if (participantData.value.data.onCreateParticipant.room === roomId) {
                    newListParticipant.unshift(participantData.value.data.onCreateParticipant);
                    // Nothing more if it is myself
                    this.setState({ participants: newListParticipant });
                    this.updateRoomMaster(newListParticipant);
                    this.draw(newListParticipant);
                }
            }
        });
    }

    subscribeDeleteParticipant(roomId) {
        return API.graphql(
            graphqlOperation(subscriptions.onDeleteParticipant, { room: roomId })
        ).subscribe({
            next: (participantData) => {
                console.log('delete participant', 'synchro')
                console.log(participantData, 'synchro')
                let newListParticipant = this.state.participants;
                // If it is me -> reload
                if (participantData.value.data.onDeleteParticipant.id === this.state.myId && !this.state.leaving) {
                    // There is no kick participant for now, so if I see this, it means that I have been wrongly kicked
                    // Recreate myself
                    console.log('--------------- Hey I am still here ! -------------------------', 'master')
                    const myself = participantData.value.data.onDeleteParticipant;
                    console.log(myself, 'master')
                    createParticipant(myself.id,
                        myself.room,
                        myself.pseudo,
                        myself.avatar,
                        myself.color,
                        myself.positionTop,
                        myself.positionLeft,
                        myself.accessory)
                    //this.setState({ myId: null })
                    //window.location.reload();
                }

                for (let i = 0; i < newListParticipant.length; i++) {
                    if (newListParticipant[i].id === participantData.value.data.onDeleteParticipant.id) {
                        newListParticipant.splice(i, 1);
                    }
                }
                // Check that I am not alone or leave too
                if (this.state.currentChat && participantData.value.data.onDeleteParticipant.id !== this.state.myId) {
                    const alone = checkIAmAlone(newListParticipant, this.state.currentChat, this.state.myId);
                    if (alone) {
                        console.log("I am alone, byebye")
                        updateParticipantChat(this.state.myId, null)
                    }
                }
                this.setState({ participants: newListParticipant });
                this.updateRoomMaster(newListParticipant);
                this.draw(newListParticipant)
            }
        });
    }

    subscribeUpdateParticipant(roomId) {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdateParticipant, { room: roomId })
        ).subscribe({
            next: (participantData) => {
                console.log('Update participant', 'synchro')
                console.log(participantData, 'synchro')
                let newListParticipant = this.state.participants;
                for (let i = 0; i < newListParticipant.length; i++) {
                    if (newListParticipant[i].id === participantData.value.data.onUpdateParticipant.id) {
                        newListParticipant[i] = participantData.value.data.onUpdateParticipant;
                    }
                }
                // If it is myself,maybe someone is contacting me, check if my current chat needs to be updated
                if (participantData.value.data.onUpdateParticipant.id === this.state.myId) {
                    // If it is me check if I need to update my lastPing
                    shouldUpdateMyPing(participantData.value.data.onUpdateParticipant)
                    if (this.state.currentChat !== participantData.value.data.onUpdateParticipant.chat) {
                        this.setChat(participantData.value.data.onUpdateParticipant.chat)
                    }
                }
                // Check that I am not alone or leave too
                if (this.state.currentChat && participantData.value.data.onUpdateParticipant.id !== this.state.myId) {
                    const alone = checkIAmAlone(newListParticipant, this.state.currentChat, this.state.myId);
                    if (alone) {
                        console.log("I am alone, byby")
                        updateParticipantChat(this.state.myId, null)
                    }
                }

                this.setState({ participants: newListParticipant });
                this.draw(newListParticipant);
            }
        });
    }
    /*
    -------------------------------------------------------------------------------------------------------
    Room master Part
    -------------------------------------------------------------------------------------------------------
    */
    // Check if the room master changed every minute or asynchronously when particiant list change
    updateRoomMaster(participantsArg = null) {
        console.log("Looking for master", 'master')
        let participants = participantsArg;
        if (!participants) participants = this.state.participants;
        // The room master is the one on the top of the id list, and active in the last 6min
        const masterObject = whosMaster(participants);
        console.log(masterObject, 'master')
        if (masterObject) {
            if ((masterObject.master && this.state.master && masterObject.master.id !== this.state.master.id) || !this.state.master) {
                console.log("new master", 'master')
                this.setState({ master: masterObject.master, participants: masterObject.participants })
            }
            setTimeout(this.updateRoomMaster, 60000)
        } else {
            setTimeout(this.updateRoomMaster, 5000)
        }
    }

    roomMasterLoop() {
        // No id, no need to check
        if (this.state.myId) {
            // First determined if I am master
            const amI = amIMaster(this.state.master, this.state.myId)
            if (amI) {
                console.log("I am room master", 'master')
                // Remove all participant with a last activity greater than 6.5min
                removeInactive(this.state.participants)
            }
        }
        setTimeout(this.roomMasterLoop, 60000)
    }

    // Check I am still in the participant list
    checkForMyselfLoop() {
        console.log('check myself', 'master')
        // No id, no need to check
        if (this.state.myId) {
            let found = false;
            for (let i = 0; i < this.state.participants.length; i++) {
                if (this.state.participants[i].id === this.state.myId) {
                    found = true;
                    console.log('check myself: I am displayed', 'master')
                    // I am in the room, check my ping
                    shouldUpdateMyPing(this.state.participants[i])
                    continue
                }
            }
            if (!found && this.state.roomId) {
                console.log("not found")
                this.init(this.state.roomId)
            }
        }
        setTimeout(this.checkForMyselfLoop, 10000)
    }

    /*
    -------------------------------------------------------------------------------------------------------
    Drawing Part
    -------------------------------------------------------------------------------------------------------
    */
    draw(participantsArg = null) {
        let participants = participantsArg;
        if (!participants) participants = this.state.participants;

        const chatClusters = generateChatsClusters(participants);
        const chats = calculateChatsPosition(chatClusters.chats)
        this.setState({ chats: chats })
    }

    /*
    -------------------------------------------------------------------------------------------------------
    Avatar movement Part
    -------------------------------------------------------------------------------------------------------
    */
    async handleDragAvatar(event) {
        // Calculate the new position in the screen ref and in the word ref
        const newScreenPosition = { top: event.clientY - event.offsetY + avatarSize / 2, left: event.clientX - event.offsetX + avatarSize / 2 }
        const newRelativePosition = screenToWord(newScreenPosition, this.state.wordPosition)

        if (!event.pageX || !event.pageY || !event.clientX || !event.clientY) {
            console.log("Off limit")
            return
        }

        const chats = checkChatProximity(this.state.chats, newRelativePosition)
        const participants = checkSoloProximity(this.state.participants, newRelativePosition, this.state.myId)
        this.setState({
            chats: chats,
            participants: participants,
        })
    }

    async handleStopAvatar(event) {
        // Calculate the new position in the screen ref and in the word ref
        const newScreenPosition = { top: event.pageY - event.offsetY + avatarSize / 2, left: event.pageX - event.offsetX + avatarSize / 2 }
        const newRelativePosition = screenToWord(newScreenPosition, this.state.wordPosition)

        const canConnect = canBeConnected(newRelativePosition.top, newRelativePosition.left)

        // Check position validity
        if (newRelativePosition.top < avatarSize / 2 || newRelativePosition.top > this.state.wordInfo.height - avatarSize / 2 ||
            newRelativePosition.left < avatarSize / 2 || newRelativePosition.left > this.state.wordInfo.width - avatarSize / 2) {
            console.log("Off limit")
            return
        }

        // Small move not taken into account
        const distance = distanceSquareTopLeft(newRelativePosition, this.state.avatarPosition)
        if (distance < 200) return

        // Check if should connect to chat
        const currentChat = this.state.currentChat;
        let chats = this.state.chats;
        let chatToConnect = null
        for (let i = 0; i < chats.length; i++) {
            if (chats[i].shouldConnect && canConnect) {
                chatToConnect = chats[i].chat;
                if (chats[i].chat !== currentChat) {
                    console.log("connect to " + chats[i].chat)
                    updateParticipantPositionChat(this.state.myId, newRelativePosition.top, newRelativePosition.left, chats[i].chat)
                    break
                } else {
                    // The use moved in the same chat
                    updateParticipantPosition(this.state.myId, newRelativePosition.top, newRelativePosition.left)
                }
            }
        }

        // If not connected check if you need to create a new connection
        if (!chatToConnect && canConnect) {
            const participants = this.state.participants;
            for (let i = 0; i < participants.length; i++) {
                if (participants[i].wantConnection) {
                    chatToConnect = await generateRandomChat();
                    console.log("chat generated at: " + chatToConnect)
                    // Update my profile with the new rooom
                    updateParticipantPositionChat(this.state.myId, newRelativePosition.top, newRelativePosition.left, chatToConnect)
                    // Update the other user with the new chat
                    updateParticipantChat(participants[i].id, chatToConnect)
                    console.log("generate connection to + " + participants[i].pseudo)
                }
            }
        }

        // If still no chat, disconnect
        if (!chatToConnect) {
            updateParticipantPositionChat(this.state.myId, newRelativePosition.top, newRelativePosition.left, null)
        }

        const participants = resetParticipantsWantConnection(this.state.participants);
        chats = resetChatShouldConnect(chats)
        this.setState({ participants: participants, chats: chats, avatarPosition: newRelativePosition })
    }

    handleStart(e, ui) { e.stopPropagation(); }

    /*
    -------------------------------------------------------------------------------------------------------
    Word drag Part
    -------------------------------------------------------------------------------------------------------
    */
    async handleStopDragWord(event) {
        console.log(event)
        // Check that the offset is more that the avatar size to prevent drag and drop with avatar handle
        if (event.offsetX < avatarSize && event.offsetY) {
            console.log("avatar drag")
            return
        }
        const newPosition = { top: event.clientY - event.offsetY, left: event.clientX - event.offsetX }
        if (!event.pageX || event.pageX > window.screen.width || event.pageX < 0 ||
            !event.pageY || event.pageY > window.screen.height || event.pageY < 0) {
            console.log("Off limit")
            return
        }
        this.setState({
            wordPosition: newPosition,
        })
    }

    /*
    -------------------------------------------------------------------------------------------------------
    Setters Part
    -------------------------------------------------------------------------------------------------------
    */
    async deleteMyself() {
        console.log('delete myself');
        await deleteParticipant(this.state.myId)
    }

    setChat(chat) {
        this.setState({ currentChat: chat })
        this.props.onUpdateChat(chat)
    }

    /*
    -------------------------------------------------------------------------------------------------------
    Donation dialog Part
    -------------------------------------------------------------------------------------------------------
    */
    closeDonationDialog() {
        this.setState({ donationDialogOpen: false })
        // Reload myself
        updateParticipantPosition(this.state.myId, this.state.avatarPosition.top, this.state.avatarPosition.left + 1)
    }

    openDonationDialog() {
        this.setState({ donationDialogOpen: true })
    }

    render() {
        const { myId, avatarPosition, wordPosition, wordInfo, participants, chats, donationDialogOpen } = this.state;
        return (
            <Box id='RoomStage'>
                <Draggable
                    defaultPosition={{ x: 0, y: 0 }}
                    position={{ x: wordPosition.left, y: wordPosition.top }}
                    onStop={this.handleStopDragWord}
                >
                    <Box id="MovingRef" style={{
                        height: wordInfo.height,
                        width: wordInfo.width,
                    }}>
                        <Box style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: noSeeZoneSize,
                            width: noSeeZoneSize,
                            backgroundColor: 'rgba(255,248,244, 0.4)',
                            borderRadius: 20
                        }} />

                        <Box style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: noConnectionZoneSize,
                            width: noConnectionZoneSize,
                            backgroundColor: 'rgba(255,248,244, 0.4)',
                            borderRadius: 20
                        }} />

                        <Box id="AvatarLayer">
                            {participants.map((user, index) =>
                                <div key={index}>
                                    {
                                        user.id !== myId ?
                                            <Box
                                                display={canBeSeen(user.positionTop, user.positionLeft) ? "inline-block" : "none"}
                                                className='avatar'
                                                style={{
                                                    backgroundColor: 'rgba(255,248,244, 0.4)',
                                                    backgroundImage: "url(" + user.avatar + ")",
                                                    height: user.wantConnection ? avatarWantConnectionSize : avatarSize,
                                                    width: user.wantConnection ? avatarWantConnectionSize : avatarSize,
                                                    top: user.wantConnection ? user.positionTop - avatarWantConnectionSize / 2 : user.positionTop - avatarSize / 2,
                                                    left: user.wantConnection ? user.positionLeft - avatarWantConnectionSize / 2 : user.positionLeft - avatarSize / 2,
                                                }}>
                                                <Typography className="avatar-name">{user.pseudo}</Typography>
                                                {
                                                    user.accessory === 'coffee' ?
                                                        <Box style={{ fontSize: 40, position: "absolute", bottom: 0, right: 0 }}>
                                                            <span role="img" aria-label="coffee">☕</span>
                                                        </Box>
                                                        :
                                                        null
                                                }
                                                {
                                                    user.accessory === 'beer' ?
                                                        <Box style={{ fontSize: 40, position: "absolute", bottom: 0, right: 0 }}>
                                                            <span role="img" aria-label="coffee">🍺</span>
                                                        </Box>
                                                        :
                                                        null
                                                }
                                            </Box> :
                                            <Draggable
                                                defaultPosition={{ x: 50, y: 50 }}
                                                position={{ x: avatarPosition.left - avatarSize / 2, y: avatarPosition.top - avatarSize / 2 }}
                                                onDrag={this.handleDragAvatar}
                                                onStop={this.handleStopAvatar}
                                                onStart={this.handleStart}>
                                                <Box className='my-avatar' style={{
                                                    backgroundColor: 'rgba(255,248,244, 0.4)',
                                                    backgroundImage: "url(" + user.avatar + ")",
                                                    height: avatarSize,
                                                    width: avatarSize,
                                                }}>
                                                    <Typography className="avatar-name">{user.pseudo}</Typography>
                                                    {
                                                        user.accessory === 'coffee' ?
                                                            <Box style={{ fontSize: 40, position: "absolute", bottom: 0, right: 0 }}>
                                                                <span role="img" aria-label="coffee">☕</span>
                                                            </Box>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        user.accessory === 'beer' ?
                                                            <Box style={{ fontSize: 40, position: "absolute", bottom: 0, right: 0 }}>
                                                                <span role="img" aria-label="coffee">🍺</span>
                                                            </Box>
                                                            :
                                                            null
                                                    }
                                                </Box>
                                            </Draggable>
                                    }
                                </div>
                            )}
                            {chats.map((chat, index) =>
                                <div key={index} >
                                    {
                                        chat.positionTop !== 0 || chat.positionLeft !== 0 ?
                                            <Box style={{
                                                position: "absolute", top: chat.positionTop, left: chat.positionLeft
                                            }} >
                                                <Box className='chat' style={{
                                                    height: chat.shouldConnect ? "40px" : "30px",
                                                    width: chat.shouldConnect ? "40px" : "30px",
                                                    top: chat.shouldConnect ? "-20px" : "-15px",
                                                    left: chat.shouldConnect ? "-20px" : "-15px",
                                                }} >
                                                    <Box className='chat-img1' />
                                                </Box>
                                            </Box>
                                            :
                                            null
                                    }
                                </div>
                            )}
                        </Box>
                    </Box>
                </Draggable>
                <Box style={{ position: "absolute", bottom: 0, left: 0, display: "flex", alignItems: "center" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size='large'
                        onClick={() => this.openDonationDialog()}
                    >
                        Get a drink
                    </Button>
                    <Box style={{ fontSize: 25, marginLeft: 10 }}>
                        <span role="img" aria-label="beer">🍻</span>
                    </Box>
                </Box>
                <DonationDialog open={donationDialogOpen} onClose={() => this.closeDonationDialog()} participants={this.state.participants} myId={this.state.myId} />
                <EnterRoomDialog onEnter={(myId, houseInfo) => this.entering(myId, houseInfo)} />
            </Box>
        );
    }
}
export default withRouter(RoomStage)