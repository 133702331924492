import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import './OnlyDesktop.scss';
import ParticlesWrapper from '../../components/ParticlesWrapper';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1c1a11',
        },
        secondary: {
            main: '#FDDA25',
        },
    },
});


class OnlyDesktop extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box id='OnlyDesktop'>
                    <Box id="particles-js"><ParticlesWrapper /></Box>
                    <Box id="content">
                        <Box className="container" display="flex" flexDirection="column">
                            <Box flexGrow={1} />
                            <Box display="flex" flexDirection="row">
                                <Box flexGrow={1} />
                                <Box className="form">
                                    <Typography variant="h3">Sorry</Typography>
                                    <Typography>We only work on desktop browser...</Typography>
                                    <Typography>We are working hard to bring you an app as soon as possible!</Typography>
                                </Box>
                                <Box flexGrow={1} />
                            </Box>
                            <Box flexGrow={1} />
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        );
    }
}
export default OnlyDesktop